import * as React from 'react';
import Highlight, {defaultProps} from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/vsDark'

const CodeBlock = (props: any) => {
    const className = props.children.props.className || '';
    const matches = className.match(/language-(?<lang>.*)/);

    return (
        <Highlight {...defaultProps}
            code={props.children.props.children.trim()}
            language={
                matches && matches.groups && matches.groups.lang
                ? matches.groups.lang : ''}
            theme={theme}>

            {({className, style, tokens, getLineProps, getTokenProps}: any) => (
            <pre className={className}
                style={{
                    ...style,
                    padding: '1rem',
                    wordBreak:"break-word",
                    whiteSpace:"pre-wrap", 
                    borderRadius:"8px"
                }}>
                {tokens.map((line: any, i: number) => (
                    <div key={i} {...getLineProps({line, key: i})}>
                        {line.map((token: any, key: number) => (
                            <span key={key} {...getTokenProps({token, key})} />
                        ))}
                    </div>
                ))}
            </pre>
            )}
        </Highlight>
    );
};

export default CodeBlock;